import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  maxRedirects: 0,
  withCredentials: true,
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN'
});

api.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  return response;
}, function (err) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  if(err.response.status===401) {
    window.location.href = process.env.REACT_APP_LOGIN_URL;
  }
  return Promise.reject(err);
});

export default api;